.rc-collapse-motion {
  transition: height .3s,opacity .3s
}

.rc-collapse-content-hidden {
  display: none
}

.rc-collapse>.rc-collapse-item:first-child {
  border-top: none
}
